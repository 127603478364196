import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { PUBLIC_ROUTE } from './Route.constants';
import Loader from '../utilities/loader/loader';
import moment from 'moment';
import ConfigActions from '../redux/config/actions';
  import mapStateToProps from '../redux/mapStateToProps';
const { 
  _Add_Elapsed_Time,
  _Log_Out,
 } = ConfigActions;


const Layout = lazy(() => import('../layout/Layout.main'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('../pages/signing/Sign.in')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    exact: true,
    component: lazy(() => import('./../pages/containers/404/index')),
  },

  {
    path: PUBLIC_ROUTE.SIGN_IN,
    exact: true,
    component: lazy(() => import('../pages/signing/Sign.in')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    exact: true,
    component: lazy(() => import('../pages/signing/Sign.up')),
  },

];

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn =  useSelector(state => state.config.isLoggedIn);
     return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const  Routes = ({isLoggedIn})=>{
  
 

  return (
      <Suspense fallback={<Loader />}>
        <Router  >
          <Switch>
            {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact} >
                    <route.component />
                </Route>
            ))}
                <PrivateRoute path="/portal">
                    <Layout /> 
                </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
  );
}
export default connect(mapStateToProps)(Routes);