const actions = {
 
//     ADD_COMPONENT_AUTH:'ADD_COMPONENT_AUTH',
//     _Log_Out : () => ({
//             type : actions.LOG_OUT,
//             }),
  
  };

export default actions;
