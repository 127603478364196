
const mapStateToProps = (state ) => {

    const { config,mitrotech } = state
    return {

        id_com_company: config.id_com_company,
        id_com_company_name:config.id_com_company_name,
        id_com_user:  config.id_com_user,
        api_url:  config.api_url,
        api_media: config.api_media,
        username: config.username,
        company_name:config.company_name,
        id_com_user_type:  config.id_com_user_type,
        loginToken: config.loginToken,
        expandAll:config.expandAll,
        callopseSidebar : config.callopseSidebar,
        components:config.components,
        component_auth:config.component_auth,
        id_com_company_type:config.id_com_company_type,
        company_short_name:config.company_short_name,
        isLoggedIn:config.isLoggedIn,
        elapsed_time:config.elapsed_time,
        data_year:config.data_year,

        mitrotech_api_url:mitrotech.mitrotech_api_url,
        mitrotech_api_media:mitrotech.mitrotech_api_media,
        customer_id_com_company:mitrotech.customer_id_com_company,

    }
}

export default mapStateToProps;