import React  from 'react';
import { Provider } from 'react-redux';
import store  from './redux/store';
import { ThemeProvider } from 'styled-components';
import Themes from './theme';
import Routes from './router/Route.main';
import 'antd/dist/antd.css'





const  App = ()=> {
 
  return (
  <Provider store={store}>
    <ThemeProvider theme={Themes.defaultTheme}>
      <Routes/>
    </ThemeProvider>
  </Provider>
  );
}

export default App;
