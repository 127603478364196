import {combineReducers} from 'redux'; 
import mitrotech from './mitrotech/reducer';
import config from './config/reducer'; 

const AllReducers =   combineReducers({
    config:config,
    mitrotech:mitrotech,
      });

export default AllReducers;

