const actions = {
    REMOVE_CONSTANT: 'REMOVE_CONSTANT',
    LOG_OUT: 'LOG_OUT',
    LOGGED_IN:'LOGGED_IN',
    ADD_USER_TYPE:'ADD_USER_TYPE',
    ADD_EMAIL:'ADD_EMAIL',
    ADD_PASSWORD:'ADD_PASSWORD',
    ADD_ID_COM_COMPANY:'ADD_ID_COM_COMPANY',
    ADD_ID_COM_USER_TYPE:'ADD_ID_COM_USER_TYPE',
    ADD_LOGIN_TOKEN:'ADD_LOGIN_TOKEN',
    ADD_USERNAME:'ADD_USERNAME',
    ADD_MANAGER: 'ADD_MANAGER',
    ADD_ID_COM_USER:'ADD_ID_COM_USER',
    CALLOPSE_SIDEBAR:'CALLOPSE_SIDEBAR',
    ADD_ID_COM_COMPANY_NAME:'ADD_ID_COM_COMPANY_NAME',
    GET_COMPONENTS:'GET_COMPONENTS',
    ADD_COMPONENT_AUTH:'ADD_COMPONENT_AUTH',
    ADD_ID_COM_DEPARTMENT:'ADD_ID_COM_DEPARTMENT',
    ADD_ID_COM_COMPANY_TYPE:'ADD_ID_COM_COMPANY_TYPE',
    ADD_COMPANY_SHORT_NAME:'ADD_COMPANY_SHORT_NAME',
    ADD_ELAPSED_TIME:'ADD_ELAPSED_TIME',
    ADD_DATA_YEAR:'ADD_DATA_YEAR',

_Add_Data_Year: (data_year) => ({
        type : actions.ADD_DATA_YEAR,
        data_year:data_year

        }),
_Add_Elapsed_Time: (elapsed_time) => ({
        type : actions.ADD_ELAPSED_TIME,
        elapsed_time:elapsed_time

        }),
_Log_Out : () => ({
        type : actions.LOG_OUT,
        }),
_Logged_In : () => ({
        type : actions.LOGGED_IN,
        }),
_Add_Id_Com_User_Type : (id_com_user_type) => ({
        type : actions.ADD_ID_COM_USER_TYPE,
        id_com_user_type:id_com_user_type
        }),
_Add_Id_Com_Company_Type : (id_com_company_type) => ({
        type : actions.ADD_ID_COM_COMPANY_TYPE,
        id_com_company_type:id_com_company_type
        }),
_Add_Email : (email) => ({
        type : actions.ADD_EMAIL,
        email:email
        }),    
_Add_Password : (password) => ({
        type : actions.ADD_PASSWORD,
        password:password
        }),
_Add_Id_Com_Company : (id_com_company) => ({
        type : actions.ADD_ID_COM_COMPANY,
        id_com_company:id_com_company
        }),
_Add_Id_Com_Department: (id_com_department) => ({
        type : actions.ADD_ID_COM_DEPARTMENT,
        id_com_department:id_com_department
        }),
_Add_Id_Com_Company_Name : (id_com_company_name) => ({
        type : actions.ADD_ID_COM_COMPANY_NAME,
        id_com_company_name:id_com_company_name
        }),
_Add_Id_Com_User : (id_com_user) => ({
        type : actions.ADD_ID_COM_USER,
        id_com_user:id_com_user
        }),
_Add_Login_Token : (loginToken) => ({
        type : actions.ADD_LOGIN_TOKEN,
        loginToken:loginToken
        }),
_Add_Username : (username) => ({
        type : actions.ADD_USERNAME,
        username:username
        }),
_Remove_Constant : () => ({
        type : actions.REMOVE_CONSTANT,
        }),     
_Add_Id_Com_Manager : (id_com_manager) => ({
        type : actions.ADD_MANAGER,
        id_com_manager:id_com_manager
        }),       
_Callopse_Sidebar :() => ({
        type:actions.CALLOPSE_SIDEBAR
        })  ,
_Get_Components : (components) => ({
        type : actions.GET_COMPONENTS,
        components:components
        }),
_Add_Component_Auth : (component_auth) => ({
        type : actions.ADD_COMPONENT_AUTH,
        components:component_auth
        }),
_Add_Company_Short_Name : (company_short_name) => ({
        type : actions.ADD_COMPANY_SHORT_NAME,
        company_short_name:company_short_name
        }),
  };
  

export default actions;
